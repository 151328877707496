import {
    Box,
    Container,
    Divider,
    Link,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";
import { AppBarDivider, WidthContainer } from "../common/styles";

const StyledBox = styled(Box)(({ theme }) => ({
    minHeight: "80vh",
    width: "100vw",
    overflow: "hidden",
    alignItems: "center"
}));

const BodyBox = styled(Box)(({ theme }) => ({
    maxWidth: "80%",
    overflow: "hidden"
}));


export function About(): React.ReactElement {
    const theme = useTheme()
    return (
        <div style={{ width: "100%" }}>
            <StyledBox justifyContent={"space-between"}>
                <AppAppBar mode="light" />
                <AppBarDivider />
                <Container maxWidth="md">
                    <Box my={5} pl={2} pr={2}>
                        <Typography mb={4} variant="h3" gutterBottom textAlign={"center"} fontFamily={"TitleFont"}>
                            About Us
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Welcome to the Quotor car valuation comparison platform, the first of its kind designed to help you make informed decisions when selling your vehicle. We believe that selling a car should be straightforward, transparent, and rewarding, which is why we’ve built a tool that puts you in control.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our mission is to empower car owners with the information they need to maximize the value of their vehicles. By providing comprehensive market insights and instant valuation comparisons, we make it easy for you to see how much your car is worth across multiple trusted platforms. No more guesswork or undervaluation—just clear, accurate, and up-to-date information at your fingertips.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            What sets us apart is our commitment to innovation and precision. As pioneers in the car valuation space, we leverage advanced algorithms and extensive data analysis to ensure that the valuations you receive are as accurate and relevant as possible. Our platform is not just a tool, but a partner in your car selling journey.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Whether you're ready to sell or just curious about your car's value, our platform is here to provide you with the insights and comparisons you need. Join us as we revolutionize the way car owners approach selling their vehicles, making the process easier, faster, and more transparent than ever before.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Thank you for choosing us as your trusted resource for car valuation comparisons. We're excited to be part of your journey and look forward to helping you achieve the best possible outcome for your car sale.
                        </Typography>
                        <Divider sx={{ width: '100%', mb: 2 }} />
                        <Typography variant="h6" gutterBottom fontFamily={"TitleFont"} paragraph>
                            The team
                        </Typography>
                        <Typography variant="subtitle2">
                            Quotor Founder
                        </Typography>
                        <Link href={"https://www.linkedin.com/in/sandy-johnstone-5a6b4518b/"} target={"_blank"}>
                            <Typography variant="h6" paragraph>
                                Sandy Johnstone
                            </Typography>
                        </Link>
                        <Typography variant="body1" paragraph>
                            Hi, I'm Sandy, the founder and full-stack engineer behind Quotor.
                            With a passion for technology and a deep understanding of the automotive industry, I set out to create a tool that simplifies the car selling process and empowers users with accurate, data-driven insights.
                            I'm committed to continuously improving the platform, ensuring it remains a trusted resource for anyone looking to maximize their car&apos;s value. Thank you for being a part of this journey with me!
                        </Typography>
                    </Box>
                </Container>
            </StyledBox>
            <Divider sx={{ width: '100%' }} />
            <Footer />
        </div>
    );
}

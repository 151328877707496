import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/QueryClient';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { applicationRoutes } from './routes';
import { HomePage } from './pages/HomePage';
import { NotFound } from './pages/NotFound';
import { QuotePage } from './pages/QuotePage';
import { Mileage } from './pages/Mileage';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import ReactGA from "react-ga4";
import { About } from './pages/About';

function App() {
  
  ReactGA.initialize('G-SXSZNXTS1E')

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path={applicationRoutes.home} element={<HomePage />}></Route>
            <Route path={applicationRoutes.quote} element={<QuotePage />}></Route>
            <Route path={applicationRoutes.mileage} element={<Mileage />}></Route>
            <Route path={applicationRoutes.about} element={<About />}></Route>
            <Route path={applicationRoutes.notFound} element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;

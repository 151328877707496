import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import { Provider } from "../interfaces/Providers";
import Quote from "../components/Quote";
import { useQueries } from "react-query";
import { getQuote } from "../api/ScraperAPI";
import { LoadBackdrop } from "../components/LoadBackdrop";
import QuoteHeader from "../components/QuoteHeader";
import QuoteSmall from "../components/QuoteSmall";
import { useEffect, useState } from "react";
import { AppBarDivider, PageContainer, ProgressBarInside, ProgressBarOutside, RegTypography } from "../common/styles";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";

const loadStrings = [
  "Contacting providers...",
  "Getting vehicle details...",
  "Retrieving quotes...",
]

const providers = Object.keys(Provider).filter((v) => isNaN(Number(v))).map((value) => {
  return value
})

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100vh",
  width: "100vw"
}));

export function QuotePage(): React.ReactElement {
  const theme = useTheme();
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const [count, setCount] = useState(0);

  let params = useParams()
  let vrm = params.vrm
  let mileage = params.mileage ? Number.parseInt(params.mileage) : undefined

  const quoteQueries = useQueries(
    providers.map((val: string) => {
      return {
        queryKey: ['quote', val],
        queryFn: () => getQuote(val, vrm!!, mileage!!),
        placeholderData: { provider: val, amount: undefined }
      }
    })
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + Math.floor(Math.random() * 6));
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Quote",
  });

  const loaded = quoteQueries.filter(value => !value.isFetching && value.data !== undefined).length
  const allLoaded = loaded == quoteQueries.length

  return (
    <PageContainer>
      <StyledBox width={"100%"} height={"100vh"} >
        <AppAppBar mode={"light"} />
        <AppBarDivider />
        {vrm && <Box display={"flex"} justifyContent={"center"}>
          <RegTypography variant="h4" textAlign={"center"} sx={{ fontFamily: "UKNumberPlate" }}>{vrm.toUpperCase()}</RegTypography>
        </Box>}
        {(count < 100 && loaded < 1) ? (
          <LoadBackdrop
            percentage={count}
            message={loadStrings[Math.min(Math.round((count / 30) * loadStrings.length), 2)]}
          />
        ) : (
          <Grid container spacing={2} padding={2}>
            <Box width={"100%"} mr={2} ml={2}>
              {!allLoaded && <CircularProgress size={"15px"} sx={{ marginRight: 2 }}></CircularProgress>}
              {loaded} results
              {!allLoaded &&
                <ProgressBarOutside>
                  <ProgressBarInside sx={{ width: `${loaded / quoteQueries.length * 100}%` }} />
                </ProgressBarOutside>
              }
            </Box>
            {onlyLargeScreen && <QuoteHeader></QuoteHeader>}
            {vrm && mileage ? (quoteQueries.sort((a, b) => (
              parseFloat(b.data?.amount ? b.data.amount.replace(",", "") : "0")
              - parseFloat(a.data?.amount ? a.data.amount.replace(",", "") : "0"))
            ).map((key, index) => (
              onlyLargeScreen ?
                key.data && <Quote
                  key={('amount' in key.data) ? key.data.amount : index.toString()}
                  quoteSize="h4"
                  provider={('provider' in key.data) ? key.data.provider : undefined}
                  amount={key.data?.amount}
                />
                : (
                  key.data && <QuoteSmall
                    key={('amount' in key.data) ? key.data.amount : index.toString()}
                    provider={('provider' in key.data) ? key.data.provider : undefined}
                    amount={key.data?.amount}
                  />
                )
            ))) : (<>404 Sorry we coudln't find that page</>)

            }
          </Grid>
        )}
        {loaded > 0 && <Divider sx={{ width: '100%' }} /> && <Footer />}
      </StyledBox>
    </PageContainer>
  );
}


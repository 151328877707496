import { alpha, createTheme, responsiveFontSizes } from '@mui/material';
import { grey } from '@mui/material/colors';

export const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: "#2d6a4f"
    },
    secondary: {
      main: "#3e2d6a"
    },
    divider: alpha(grey[400], 0.5),
  },
  typography: {
    fontFamily: "Helvetica"
  }
}));

import { Backdrop, Box, CircularProgress, Typography } from "@mui/material"
import { CirclesWithBar } from "react-loader-spinner"
import { theme } from "../theme"
import { grey } from "@mui/material/colors"

interface LoadBackDropProps {
    message: string,
    percentage: number
}

export function LoadBackdrop({ message, percentage }: LoadBackDropProps) {

    return (
            <Box
                mt={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",                    
                }}>
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    style={{ fontFamily: "TitleFont", fontWeight: "bold" }}
                    sx={{
                        color: 'primary.main',
                    }}
                >
                    LOADING QUOTES
                </Typography>
                <Typography mb={10} color={grey[700]} variant="subtitle1">This can take up to 2 minutes</Typography>
                <CirclesWithBar
                    height="100"
                    width="100"
                    color="#2d6a4f"
                    outerCircleColor={theme.palette.primary.dark}
                    innerCircleColor="#fff"
                    barColor={theme.palette.primary.main}
                    ariaLabel="circles-with-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}

                />

                <Typography
                    variant="h6"
                    component="div"
                    color={grey[700]}
                    mb={1}
                    mt={10}
                >{`${percentage}%`}</Typography>


                <Typography color={grey[700]} variant="subtitle1">{message}</Typography>
            </Box>
    
    )
}
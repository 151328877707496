import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBarDivider, PageContainer, RegTypography, StyledDoneIcon, WidthContainer } from "../common/styles";
import QuoteHeader from "../components/QuoteHeader";
import Quote from "../components/Quote";
import QuoteSmall from "../components/QuoteSmall";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import AppAppBar from "../components/AppAppBar";
import Highlights from "../components/Highlights";
import FAQ from "../components/FAQ";

const RegInput = styled(TextField)(({ theme }) => ({
  fontSize: 'h1',
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(1),
  input: {
    backgroundColor: "white",
  },
  width: "50%",
}));


export function HomePage(): React.ReactElement {
  const theme = useTheme();
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const [vrm, setVrm] = useState<string>("");
  const navigate = useNavigate();

  const [validVrm, setValidVrm] = useState<boolean>(true);

  const handleVrmChange = useCallback((value: string) => {
    if (value.length <= 7) {
      setValidVrm(true)
      setVrm(value)
    }
  }, [setVrm, setValidVrm])

  const handleClick = () => {
    if (vrm.length > 0) {
      navigate(`/mileage/${vrm}`)
    }
    if (vrm.length == 0) {
      setValidVrm(false)
    }
  }

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });

  return (
    <PageContainer>
      <AppAppBar mode="light" />
      <AppBarDivider/>
      <WidthContainer>
        <Box width={"100%"} display={"flex"} justifyContent={"center"} mb={4}>
          <Typography
            variant="h2"
            textAlign={"center"}
            flexDirection={{ xs: 'column', lg: 'row' }}
            style={{
              display: 'flex',
              fontFamily: "TitleFont", fontWeight: "bold"
            }} >
            Get the best&nbsp;
            <Typography
              variant="h2" textAlign={"center"} style={{ fontFamily: "TitleFont", fontWeight: "bold" }}
              sx={{
                color: 'primary.main'
              }}
            >{"PRICE"}&nbsp;</Typography>
            for your car
          </Typography>
        </Box>
        <Box width={"80%"} display={"flex"} justifyContent={"center"} mb={4}>
          <Typography variant="subtitle1" textAlign={"center"}>
            Quotor allows you to get quotes for the sale of your car from all the top providers in one place.
            Including valuations from Carwow, Motorway, WeBuyAnyCar, Arnold Clark, and more.
            Whether you are selling your car with one of our providers, leveraging a private sale, or simply want
            to know what your car is worth, you can find out quickly at Quotor.
          </Typography>
        </Box>
        <List sx={{ marginBottom: 4 }}>
          <ListItem>
            <ListItemIcon>
              <StyledDoneIcon />
            </ListItemIcon>
            <ListItemText>
              No email required
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledDoneIcon />
            </ListItemIcon>
            <ListItemText>
              Multiple valuations
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledDoneIcon />
            </ListItemIcon>
            <ListItemText>
              30-second process
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledDoneIcon />
            </ListItemIcon>
            <ListItemText>
              Completely Free
            </ListItemText>
          </ListItem>
        </List>
        <Box width={"100%"} display={"flex"} height={"70px"} mb={4}>
          <RegInput
            inputProps={{
              style: {
                fontSize: "26px",
                textAlign: "center",
                fontFamily: "UKNumberPlate",
              }
            }}
            placeholder="Enter reg"
            value={vrm}
            error={!validVrm}
            onChange={(event) => handleVrmChange(event.target.value)}
            helperText={!validVrm && "Please enter your mileage"}
          />
          <Button variant="contained" sx={{ width: "50%", height: "100%" }} onClick={() => handleClick()}>
            {"Get quotes"}
          </Button>

        </Box>
        <Typography color={"grey"} mb={4}>+2,000 valuations</Typography>
        <Box 
          width={"90%"}
          height={"100vh"}
          border={"20px solid black"}
          overflow={"hidden"}
          mb={4}
          pt={4}
          sx={{boxShadow: `0 0 12px 8px ${alpha('#2d6a4f', 0.4)}`}}
          borderRadius={12}>
          <Grid container spacing={2} padding={2}>
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              <RegTypography variant="h4" textAlign={"center"} sx={{ fontFamily: "UKNumberPlate" }}>YoUR REG</RegTypography>
            </Box>
            {onlyLargeScreen && <QuoteHeader />}
            {onlyLargeScreen ?
              <>
                <Quote quoteSize="h6" provider="carwow" amount="12,500" />
                <Quote quoteSize="h6" provider="motorway" amount="12,340" />
                <Quote quoteSize="h6" provider="webuyanycar" amount="12,100" />
                <Quote quoteSize="h6" provider="money4yourmotors" amount="11,980" />
                <Quote quoteSize="h6" provider="motorpoint" amount="11,500" />
                <Quote quoteSize="h6" provider="whatcar" amount="11,200" />
              </>
              : (
                <>
                  <QuoteSmall provider="carwow" amount="12,500" />
                  <QuoteSmall provider="motorway" amount="12,340" />
                  <QuoteSmall provider="webuyanycar" amount="12,100" />
                  <QuoteSmall provider="money4yourmotors" amount="11,980" />
                </>
              )}
          </Grid>
        </Box>
      </WidthContainer>
      <Highlights />
      <FAQ />
      <Divider sx={{ width: '100%' }} />
      <Footer />
    </PageContainer>
  );
}

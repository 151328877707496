import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import MoneyRoundedIcon from '@mui/icons-material/MoneyRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';

const items = [
    {
        icon: <SearchOffRoundedIcon />,
        title: 'Comprehensive Market Insights',
        description:
            'Access a wide range of up-to-date car valuations from multiple sources, giving you a complete view of the market.',
    },
    {
        icon: <SpeedRoundedIcon />,
        title: 'Instant Valuation Results',
        description:
            'Get quick and accurate car valuations at your fingertips, saving you time and effort in your selling journey.',
    },
    {
        icon: <MoneyRoundedIcon />,
        title: 'Maximize Your Car’s Value',
        description:
            'Compare offers and choose the best deal to ensure you’re getting the most out of your car sale.',
    },
    {
        icon: <SecurityRoundedIcon />,
        title: 'Secure and Trusted Comparisons',
        description:
            'Rest easy knowing our platform provides secure and reliable comparisons, backed by trusted industry data.',
    },
    {
        icon: <InsightsRoundedIcon />,
        title: 'Data-Driven Accuracy',
        description:
            'Our advanced algorithms analyze extensive data to deliver precise valuations, reflecting real-time market trends.',
    },
    {
        icon: <FlagRoundedIcon />,
        title: 'Pioneering Innovation',
        description:
            'Be part of a groundbreaking experience with the first-ever platform to offer such comprehensive and precise car valuation comparisons.',
    },
];

export default function Highlights() {
    return (
        <Box
            width={"100%"}
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Discover what sets our platform apart: comprehensive market insights, instant results, and the ability to maximize your car’s value. Experience secure and trusted comparisons, data-driven accuracy, and pioneering innovation. Be among the first to access a groundbreaking tool for car valuation comparisons.
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid pb={6} item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
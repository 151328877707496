import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import TrustBoxContainer from "./TrustPilotWidget";
import { StyledRemoveIcon, StyledDoneIcon, StyledLogoCard, StyledOfferBox } from "../common/styles";
import { linkMap } from "../common/Data";
import { expiry } from "../common/Expiry";

const StyledQuoteBox = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderColor: theme.palette.secondary.light
}));


const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,

}))

const StyledGoButton = styled(Button)(({ theme }) => ({
    borderRadius: 16
}))

interface QuoteProps {
    amount: string | undefined,
    provider: string | undefined
}


export default function QuoteSmall({ provider, amount }: QuoteProps) {
    let displayString = amount
    const offerProvider = (provider == "carwow" || provider == "motorway")

    if (displayString) {
        displayString = displayString.replace(".00", "")
        displayString = displayString.replace("*", "")
    }


    if (provider) {
        return (
            <Grid item xs={12} md={6} position={"relative"}>
                {offerProvider &&
                    <StyledOfferBox sx={{
                        left: "50%",
                        transform: "translate(-50%, 0)"
                    }}>
                        <div style={{ color: "white" }}>£50 Amazon Voucher </div>
                    </StyledOfferBox>

                }
                <StyledQuoteBox border={offerProvider ? "5px solid" : "0px solid"}>
                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
                        <StyledLogoCard
                            sx={{ backgroundImage: `url(${process.env.PUBLIC_URL + `/${provider}.png`})` }}
                        />
                        {amount ? (
                            <StyledTypography variant="h3"
                                color={"#2d6a4f"}
                                style={{ fontFamily: "TitleFont" }}>
                                £{displayString}
                            </StyledTypography>
                        ) : (
                            <CircularProgress></CircularProgress>
                        )}
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <List sx={{ marginBottom: 2 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <StyledDoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Expires in {expiry(provider).days} day{expiry(provider).days == 1 ? "" : "s"}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                {expiry(provider).settlesFinance ?< StyledDoneIcon /> : <StyledRemoveIcon/>} 
                                </ListItemIcon>
                                <ListItemText>
                                    {expiry(provider).settlesFinance ? ("Settles Finance") : ("Doesn't Settle Finance")}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                {expiry(provider).auction ? <StyledDoneIcon /> : <StyledRemoveIcon/>}
                                </ListItemIcon>
                                <ListItemText>
                                {expiry(provider).auction ? ("Dealer Auction") : ("No Dealer Auction")}
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                    <Box mb={1} >
                        <TrustBoxContainer provider={provider} variant="small" />
                    </Box>
                    <StyledGoButton variant="contained"
                        onClick={() => window.open(linkMap.get(provider))}>
                        Get Offer
                    </StyledGoButton>
                </StyledQuoteBox>
            </Grid >
        );
    }
    return <></>
}

import axios from 'axios';


const scraperAxiosClient = axios.create({
    baseURL: 'https://f45rqyktf5ycx5c3tsngbgwpmu0egsjo.lambda-url.eu-west-2.on.aws',
});

const errorHandler = (error: any) => {
    console.log(error)
    if (axios.isAxiosError(error)) {
        console.log(error.status);
        console.error(error.response);
    } else {
        console.log(error);
    }
}

export default scraperAxiosClient; 
import { Provider } from "../interfaces/Providers"

interface ProviderProps {
    days: number,
    settlesFinance: boolean,
    auction: boolean
}


export function expiry(provider: string): ProviderProps {
    let days = 1
    let settlesFinance = false
    let auction = false
    switch (provider) {
        case Provider[Provider.arnoldclark]:
            days = 7
            settlesFinance = true
            break
        case Provider[Provider.carwow]:
            days = 5
            auction = true
            settlesFinance = true
            break
        case Provider[Provider.motorway]:
            days = 7
            auction = true
            settlesFinance = true
            break
        case Provider[Provider.webuyanycar]:
            days = 4
            settlesFinance = true
            break
        case Provider[Provider.motorpoint]:
            days = 7
            settlesFinance = true
            break
        case Provider[Provider.money4yourmotors]:
            days = 5
            settlesFinance = true
            break
    }
    return {
        days: days,
        settlesFinance: settlesFinance,
        auction: auction
    }

}
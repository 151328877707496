import React from "react";
import { Component } from "react";

declare global {
    interface Window {
        Trustpilot: any;
    }
}

type variant = "large" | "small" | "micro" | "review"

interface TrustBoxProps {
    trustBoxRef: any,
    provider: string
    variant: variant
}

const trustPilotMap = new Map<string, string>([
    ["webuyanycar", "4bddbdb3000064000506514d"],
    ["arnoldclark", "4bdc6e65000064000505c245"],
    ["whatcar", "59f9b5c20000ff0005af3e2e"],
    ["carwow", "51e3094d000064000557a58b"],
    ["motorpoint", "5192f0c200006400052c7008"],
    ["motorway", "59f1a7fc0000ff0005aeb29e"],
    ["money4yourmotors", "5140bb6c000064000523e5da"]
]);

const trustPilotTemplateMap = new Map<string, string>([
    ["large", "53aa8807dec7e10d38f59f32"],
    ["small", "5419b732fbfb950b10de65e5"],
    ["micro", "5419b6ffb0d04a076446a9af"],
    ["review", "54ad5defc6454f065c28af8b"]
])

const TrustBox = ({ trustBoxRef, provider, variant }: TrustBoxProps) => (
    <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-template-id={trustPilotTemplateMap.get(variant)}
        data-businessunit-id={trustPilotMap.get(provider)}
        data-locale="en-GB"
        data-text-color="#464646"
        data-style-width="100%"
        data-style-height={variant === "large" ? "120px" : "50px"}
    >
    </div>
);


class TrustBoxContainer extends Component<{ provider: string, variant: variant }> {
    trustBoxRef: React.RefObject<unknown>;
    provider: string;
    variant: variant;
    constructor(props: any) {
        super(props);
        this.provider = props.provider
        this.variant = props.variant
        this.trustBoxRef = React.createRef();
    }
    componentDidMount() {
        var aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        aScript.async = true
        document.head.appendChild(aScript);
        aScript.onload = function () {
            var trustbox = document.getElementById('trustbox');
            window.Trustpilot.loadFromElement(trustbox);
        };
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }
    render() {
        return <TrustBox provider={this.provider} trustBoxRef={this.trustBoxRef} variant={this.variant}  />;
    }
}

export default TrustBoxContainer;
import { Box, Typography, styled } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';

export const StyledLogoCard = styled(Box)(({ theme }) => ({
    height: "125px",
    width: "125px",
    [theme.breakpoints.down("md")]: {
        height: "100px",
        width: "100px",

    },
    borderRadius: theme.shape.borderRadius * 2,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat"
}));


export const WidthContainer = styled(Box)(({ theme }) => ({
    width: "80%",
    [theme.breakpoints.down("md")]: {
        width: "95%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}));


export const PageContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

}));


export const StyledOfferBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "1%", left: "80%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius * 3,
    padding: theme.spacing(0.7)
}))


export const ProgressBarOutside = styled(Box)(({ theme }) => ({
    height: "10px",
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2)
}));

export const ProgressBarInside = styled(Box)(({ theme }) => ({
    height: "10px",
    width: "50%",
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
}));

export const RegTypography = styled(Typography)(({ theme }) => ({
    backgroundColor: "#fed500",
    borderRadius: theme.shape.borderRadius * 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}));

export const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
    color: "green"
}));


export const StyledRemoveIcon = styled(RemoveIcon)(({ theme }) => ({
    color: "grey"
}));

export function AppBarDivider(): React.ReactElement {
    return (
        <Box sx={{
            backgroundImage: 'linear-gradient(180deg, #c0d2ca, #FFF)',
            backgroundSize: '200% 70%',
            backgroundRepeat: 'no-repeat',
            width: "100%",
            pt: { xs: 8, sm: 14 },
            pb: { xs: 8, sm: 12 },
        }} />
    )
} 
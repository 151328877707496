import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { grey } from '@mui/material/colors';


const StyledAccordion = styled(Accordion)(({ theme }) => ({
    padding: 8,
    overflow: 'clip',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: grey[100],
    ':before': {
        backgroundColor: 'transparent',
    },
    '&:first-of-type': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    '&:last-of-type': {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: grey[900],
        borderColor: grey[800],
    }),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    border: 'none',
    borderRadius: 8,
    '&:hover': { backgroundColor: grey[100] },
    ...(theme.palette.mode === 'dark' && {
        '&:hover': { backgroundColor: grey[800] },
    }),
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    mb: 20,
    border: 'none'
}));

export default function FAQ() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Container
            id="faq"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                Frequently asked questions
            </Typography>
            <Box sx={{ width: '100%' }}>
                <StyledAccordion
                    elevation={0}
                    disableGutters={true}
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Typography component="h3" variant="subtitle2">
                            How does Quotors car valuation comparison tool work?
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                        >
                            Our tool aggregates data from multiple trusted sources to provide you with a comprehensive overview of your car&apos;s value. By entering your vehicle&apos;s details, you&apos;ll receive instant valuations from various platforms, allowing you to compare and choose the best offer.
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                    elevation={0}
                    disableGutters={true}
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <Typography component="h3" variant="subtitle2">
                            Is the car valuation provided accurate?
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                        >
                            Yes, our valuations are based on real-time data and advanced algorithms that analyze market trends, recent sales, and various other factors to ensure the accuracy of the information provided. However, actual offers may vary based on the specific condition of your car and other variables.
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                    elevation={0}
                    disableGutters={true}
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <Typography component="h3" variant="subtitle2">
                            Is your service free to use?
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                        >
                            Yes, our car valuation comparison tool is completely free to use. You can access multiple valuations without any cost or obligation.
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                    elevation={0}
                    disableGutters={true}
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                >
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                    >
                        <Typography component="h3" variant="subtitle2">
                            What makes your platform different from other car valuation sites?
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                        >
                            Our platform is the first of its kind to offer a truly comprehensive comparison across multiple valuation sources. We provide not just a single estimate but a range of valuations, empowering you to choose the best option for your needs.
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        </Container>
    );
}
import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppBarDivider, PageContainer, ProgressBarInside, ProgressBarOutside, RegTypography, WidthContainer } from "../common/styles";
import AppAppBar from "../components/AppAppBar";

const RegInput = styled(TextField)(({ theme }) => ({
  fontSize: 'h2',
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(1),
  input: {
    backgroundColor: "white",
  },
  width: "50%",
  maxWidth: "500px"
}));


export function Mileage(): React.ReactElement {
  let params = useParams()
  let vrm = params.vrm
  const [mileage, setMileage] = useState<string>("");
  const navigate = useNavigate();

  const [validMileage, setValidMileage] = useState<boolean>(true);

  const handleClick = () => {
    const parsedMileage = parseInt(mileage.replaceAll(",", ""))
    if (parsedMileage) {
      navigate(`/quote/${vrm}/${parsedMileage}`)
    }
    if (!parseInt(mileage)) {
      setValidMileage(false)
    }
  }

  const handleMileageChange = useCallback((value: string) => {
    const parsedInt = parseInt(value.replaceAll(",", ""))
    if (999999 >= parsedInt && parsedInt >= 0) {
      setValidMileage(true)
      setMileage(parsedInt.toLocaleString())
    } else if (value == "") {
      setMileage(value)
    }
  }, [setMileage, setValidMileage])

  return (
    <PageContainer>
      <AppAppBar mode="light" />
      <AppBarDivider/>
      <WidthContainer>
        <Box width={"95%"} display={"flex"} justifyContent={"center"} flexDirection={"column"} mb={4}>
          <ProgressBarOutside>
            <ProgressBarInside />
          </ProgressBarOutside>
          <Typography mb={2} variant="h2" textAlign={"center"} style={{ fontFamily: "TitleFont", fontWeight: "bold" }} >
            Last step
          </Typography>
          {vrm && <Box display={"flex"} justifyContent={"center"}>
            <RegTypography variant="h4" textAlign={"center"} sx={{ fontFamily: "UKNumberPlate" }}>{vrm?.toUpperCase()}</RegTypography>
          </Box>}
        </Box>
        <Box width={"100%"} display={"flex"} justifyContent={"center"} mb={4}>
          <Typography variant="h6" textAlign={"center"}>
            Please enter your mileage so we can get you an accurate valuation
          </Typography>
        </Box>

        <Box width={"100%"} display={"flex"} height={"70px"} justifyContent={"center"} >
          <RegInput
            inputProps={{
              style: {
                fontSize: "26px",
                textAlign: "center",
                // fontFamily: "UKNumberPlate",
              }
            }}
            variant="outlined"
            placeholder="e.g. 26,000"
            value={mileage}
            error={!validMileage}
            onChange={(event) => handleMileageChange(event.target.value)}
            helperText={!validMileage && "Please enter an accurate mileage"}
          />
          <Button variant="contained" sx={{ width: "50%", height: "100%", maxWidth: "500px" }} onClick={() => handleClick()}>
            {"See Quotes"}
          </Button>
        </Box>
      </WidthContainer>
    </PageContainer>
  );
}


import Typography from "@mui/material/Typography";
import { Grid, styled } from "@mui/material";


const StyledQuoteBox = styled(Grid)(({ theme }) => ({
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    borderBottom: "7px solid",
    borderColor: theme.palette.grey[400],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    alignItems: "center",
    backgroundColor: "#2d6a4f"
}));


const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "white",
}))


const StyledTextContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    width: "100%",
    justifyContent: "center"
}))




export default function QuoteHeader() {

    return (
        <Grid item sm={12}>
            <StyledQuoteBox container>
                <StyledTextContainer item xs={1} alignItems={"center"}>
                    <StyledTypography variant="h5">Provider</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={2}>
                    <StyledTypography variant="h5">Price</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={2}>
                    <StyledTypography variant="h5">Trustpilot</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={2}>
                    <StyledTypography variant="h5">Valid for</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={2}>
                    <StyledTypography variant="h5">Settles Finance</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={2}>
                    <StyledTypography variant="h5">Dealer Auction</StyledTypography>
                </StyledTextContainer>
                <StyledTextContainer item xs={1}>
                    <StyledTypography variant="h5">Offer</StyledTypography>
                </StyledTextContainer>
            </StyledQuoteBox>
        </Grid >
    );
}

import { UseQueryResult, useQuery } from "react-query";
import scraperAxiosClient from "./ScraperAxiosConfig";
import { Quote } from "../interfaces/Quote";
import authAxiosClient from "./AuthAxiosConfig";
import { Auth } from "../interfaces/Auth";

export const getQuote = async (provider: string, vrm: string, mileage: number) => {
    const auth = await authAxiosClient.get<Auth>(`/auth`, {
        headers: {
            "x-api-key": process.env.REACT_APP_AUTH_API_KEY
        }
    });

    const response = await scraperAxiosClient.get<string>(`/`, {
        headers: {
            "X-Amz-Date": auth.data.headers["X-Amz-Date"],
            Authorization: auth.data.headers.Authorization,
            "provider": provider,
            "vrm": vrm,
            "mileage": mileage
        }
    });
    return { provider: provider, amount: response.data }
};

export function useGetQuote(provider: string, vrm: string, mileage: number): UseQueryResult<Quote, Error> {
    return useQuery(["invited by meet id", provider, vrm, mileage], () => getQuote(provider, vrm, mileage), { retry: 2 })
}
